// src/ShareButton.js 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {faShare } from '@fortawesome/free-solid-svg-icons'

import React from 'react';
 // Declair the local icon. this will change the buttons icon to the one being passed i.e msg, twitter...
        let _icon;
const ShareButton = ({ type, url, icon }) => {
      
       
        // Lets get the "icon" passed for the button being built<FontAwesomeIcon />
        if (icon === 'faShare') {
          _icon =  faShare
        } else if (icon === 'faEnvelope') {
          _icon =  faEnvelope
        }
    const handleClick = () => {
        let href = '';
        if (type === 'email') {
            const subject = encodeURIComponent('Check out this website');
            const body = encodeURIComponent(`I found this website and thought you might like it: ${url}`);
            href = `mailto:?subject=${subject}&body=${body}`;
        } else if (type === 'sms') {
            const body = encodeURIComponent(`Check out this website: ${url}`);
            href = `sms:?&body=${body}`;
        } window.location.href = href;
      
    };
    
  
    return (
        
        <button className='share-button ' onClick={handleClick}><FontAwesomeIcon icon={_icon} /> share </button>
    );
};

export default ShareButton;

