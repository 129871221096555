import React from "react";
import ShareButton from './ShareButton';

function Footer() {

    //  ----------------- copy right  ----------------------
    const d = new Date();
let year = d.getFullYear();
    let copyRightDate = `${year} River Side Design All rights reserved`;

     
    
     // Share button url for my site
     const url = 'https://www.riversideWebDevelopment.com/'; 
    return (
        <>
            <div id="footer">
                
                <div id="share-div"> 
            <ShareButton type="email" url={url} icon="faEnvelope" />
                    <ShareButton type="sms" url={url} icon="faShare" />
                   
                    </div>
     <div id="copy-right-div">
                    <h3 id="copy-right">&copy; {copyRightDate}</h3>
                    </div>
        </div>
        </>
    )
}

export default Footer;