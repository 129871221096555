import React, { createContext, useContext, useState } from "react";

// Create new context for global state
const GlobalStateContext = createContext();

// Create a Provider componant
// This componant will be the provider for the global states context


export const GlobalStateProvider = ({ children }) => {
    

    // 
    const [isOpen, setIsOpen] = useState({
        contact: true,
        about: true,
        bool3: true,
    });
    //useState hook initializes state with three boolean values

    const toggleBool = (key) => {
        
        setIsOpen((prevState) => ({
            ...prevState,// Spread the previous state to keep other values unchanged

            [key]: !prevState[key],
            
        }));
    };
    return (

        // provides the state and toggle function to any componant wrapped in this provider
        <GlobalStateContext.Provider value={{ isOpen, toggleBool }}>
            {children}
        </GlobalStateContext.Provider>
    );
};


// Custom hook to use the GlobalStateContext. It simplifies accessing the context in components
export const useGlobalState = () => useContext(GlobalStateContext)
