import React, { useState } from "react";
import Button from "./Button";
import changeClipPath from "./js/card.js";
// import WaterDrop from "./WaterDrop.jsx";
import { useGlobalState} from './GlobalStateContext.jsx';
function NavBar(props) {

    // This is to control the showing and closing the page based on each page bool state. according to the state the needed class 
    // will be added to the page div
  const { isOpen, toggleBool } = useGlobalState();

  console.log(isOpen)
  
   
    // This handles the link slideout on smaller screens. it will add and remove the 'active' class based on the bool
    // 'isActive' this class will add the styling which is controlled in styles.css
    const [isActive, setIsActive] = useState(false);

 
    const handleToggle = (e) => {
      
            setIsActive(!isActive);

        
        
    };
   
    
    // useEffect(() => {
    //     // Listen for a click on the body to close the slideout 
    //     document.body.addEventListener('click', handleToggle);
        

    //     // Cleanup function to remove the listner to prevent possible memory leaks
    //     return () => {
    //         document.body.removeEventListener('click', handleToggle);
    //     }
    // });

    
  return (
    <>
      <nav className="navbar">
        {" "}
              <div className="logo">
                   
                  {/* <h1>LR</h1> */}
              {/* <img className="" src="src/logo.png" alt="" /> */}
              
              </div>
              {/* <div className="close-div"> */}
              <ul className={`nav-links ${isActive ? 'active' : ''}`}>
                  <li>
                      <div className="hamburger close-div" onClick={handleToggle}>
                          
                      {/* className close-slideout is only used for styling*/}
          <span className="close-x-one"></span> <span className="close-x-two"></span>{" "}
                      </div>{" "}
                  </li>
          {/* <li >
            < Button class="light-btn " text="Home" link="#home"/>
          </li> */}
                  {/* <li>
                      < Button class="light-btn " text="Services"link="#services"/>
            
          </li> */}
                  <li>
                      {/* About button. Opens the about page display */}
                       < Button class="light-btn " onClick={(e) => {handleToggle(e); changeClipPath(e,false); toggleBool('about'); }} text="About"link="#about"/>
            {/* <a href="#about">About</a> */}
          </li>
          <li>
                      {/* 
                      onClick: changeClipPath this function will perform the logic to show the popup and change 
                      the background as needed.
                      theId: */}
                     
                      {/* Contact: Opens the contact display */}
                      < Button class="light-btn " onClick={(e) => { handleToggle(e); changeClipPath(e,false); toggleBool('contact'); }} text="Contact" link="#contact"/>
                      {/* <WaterDrop waterDropState={showDrop } /> */}
                          {/* <div className={` ${showDrop ? 'water-drop' : ' '}`} ></div> */}
                         
                    
                  </li>
              </ul>{" "}
              
              {/* this will open and close the slideout on smaller screens by adding the class "active"
                  any time a link is clicked it will close the slide out as well. this is to allow the popup to show */}
        <div className="hamburger" onClick={handleToggle}>
          <span></span> <span></span> <span></span>{" "}
                  </div>{" "}
                  {/* </div> */}
      </nav>
    </>
  );
}

export default NavBar;
