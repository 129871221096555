 
  /**
     * Purpose: Create a ripple effect on the clicked obeject
     *  location of file: The handleClick function is in rippleClick.js.
       Disc: a span is created and used as the ripple container. The "button" will be the get the container that needs the affects
       applied to. the js logic is used to get the postion of the mouse click and set the size of the ripple according to the containers
       size. (rect = button.getBoundingClientRect()). the styling for the ripple is aplied in "ripple.css". Here to ripple is set to
       a circle with border-radius: 50%. The background is set to white and a opacity of .2. the size of the ripple is initially set to 
       0 with transform: scale(0). Then using keyframes "animation: ripple-animation 0.6s linear" the ripple grows outward by changing it's
       size to 4 times over a piriod of .6 second. the "linear" will have the animation take place evenly from scale(0) to scale(4), the 
       opacity is also slowly set to 0 to fade the ripple out
       Use: Add onClick={handleClick} to the container I want to listen for the click
       Note: This can be applied to any container because it is using e.target to perform the logic
     */


const handleClick = (e) => {

const rippleContainer = document.querySelector('.ripple-container');
            const button = e.currentTarget;// get the container that triggered the click
        //  console.log(e.target.classList)
            
            const ripple = document.createElement('span');// creating a element to use as the ripple

            const rect = button.getBoundingClientRect();// get the buttons width, height ...

            const size = Math.max(rect.width, rect.height); //how big is the button 
 
            const x = e.clientX - rect.left - size / 2;// get the x position of the mouse click

            const y = e.clientY - rect.top - size / 2;// get the y position of the mouse click

            ripple.style.width = ripple.style.height = `${size}px`;
            
            ripple.style.left = `${x}px`;// set the left 
            ripple.style.top = `${y}px`;// set the top

            //  all the styling is in the class. everything to this point is creating the size and the position of the ripple
            ripple.className = 'ripple';// give the class
    
    // Check for the div that covers the background.
    // if (rippleContainer != undefined) {
        
    //     rippleContainer.style.zIndex = "1";// put over the button to detect mouse up on the container
        
       
    //     rippleContainer.click(e);
    // }
  
  

            button.appendChild(ripple);
  ripple.addEventListener("animationiteration", () => {
    // alert('THE ANIMATION IS ENDED')
  });
  ripple.addEventListener('animationend', () => {
    ripple.remove();
    
       // Check for the div that covers the background.
    // if (rippleContainer !== undefined) {
    //   rippleContainer.style.zIndex = "-1";// set the conainer zindex behind the button
    // }
                
            });

  };
        

export default handleClick;