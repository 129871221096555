import React from "react";
// import changeClipPath from "./js/card.js";
function Button(props) {


    return (<>
        {/* Link: if used pass the link to the page to visit
        onClick: this a function to call if needed
        class: the class to add. 
        text: any text
        Purpose: see css for each link buttons full functions and discription
        href: This is used to trigger the css target. the id for the  div to show uses 
         "target". the href needs to match the "id" of the div the is being change.
         
     */}
        <a href={props.link} type="submit" onClick={props.onClick}  className={props.class}>{props.text}</a>
       
    </>)
    
}

export default Button;