
import React from 'react';

// 'react-router-dom' for chaging routes for switchiching for email
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Body from "./Body.jsx";
import Heading from "./Heading.jsx";
const ReviewRequest=   React.lazy(() => import ('./ReviewRequest')); // New review request component




// function newEntry(data) {
//   return (
    // <dl className="dictionary">
    //   <Entry key={data.id} props={data} />
    // </dl>
//   );
// }
function App() {
  return (
    <div>
     
  
        
       <Router>
            <Routes>
                <Route path="/" element={<Heading />} /> {/* Route for home component */}
                {/* <Route path="/calendar" element={<Calendar />} /> Route for calendar component */}
                <Route path="/review-request" element={<ReviewRequest />} /> {/* Route for review request component */}
            </Routes>
        </Router>

          {/* <Heading /> */}
    </div>
  );
}

export default App;
