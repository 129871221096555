// import React, { useContext } from "react";


let opac = 1;
//  const [isOpen, setIsActive] = useState(false);

// close checks if the close button on the page or a link called the funtion. This will fade the logo in and out accordingly
function changeClipPath(e, close) {
    // alert(e.target)
    e.preventDefault();
    // find the card that has an id that matched the e.target id and use this to add the class to show the div

    // const { isOpen, setIsOpen } = useContext(GlobalStateContext);


    // setIsOpen(!isOpen);
    // hid the logo in the nav bar so is can fade in the page display
    const logo = document.querySelector('.logo');


    // reduce the opacity of the nav bar logo to highlight the logo on the card page
    

        // The page was already open and is now being closed. Fade the logo back in.
    if (close) {
       
        const reduceOpacity = setInterval(function () {
                //  alert(opac)
            opac += .1;
                   logo.style.opacity = opac;
            // the new page is being opened. Fade the logo out.
           if (opac === 1) {
                clearInterval(reduceOpacity);
           }
            }, 100);
    } else if (!close){
        // alert(opac)
        const reduceOpacity = setInterval(function () {
            opac -= .1;
            logo.style.opacity = opac;
       
            if (opac < 0.1) {
                clearInterval(reduceOpacity);
            }
             }, 100);
        }
    

  

    // const background = document.querySelector(".header");
    // alert();
    // background.style.clipPath = 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)';
}


export default changeClipPath;