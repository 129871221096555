 import handleClick from "./rippleClick";
import { useGlobalState } from "./GlobalStateContext";
const RippleButton = (props) => {

    /**
     * Purpose: Create a ripple effect on the clicked obeject
     *  location of file: The handleClick function is in rippleClick.js.
       Disc: This can be applied to any container because it is using e.target to perform the logic
       Use: Add onClick={handleClick} to the container I want to listen for the click
       **/
const { isOpen, toggleBool } = useGlobalState();

    console.log(isOpen)
     return (
        
     <>
             
             {/* <button className="ripple-button " onClick={handleClick}> click me</button> */}
             <a href={props.link} type={props.type} className={props.class} onMouseEnter={(e) => { handleClick(e) }} onClick={(e) => { handleClick(e); toggleBool(props.page) }} >{props.text}</a>
              {/* <a href={props.link}  onClick={props.onClick}  className={props.class}>{props.text}</a> */}
             {/* <div className="ripple-container" onClick={handleClick} > */}
                 
             {/* </div> */}
         </>
     )
 }
    
export default RippleButton;