import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';


import App from './componants/App.jsx'
import './ripple.css';
import './index.css'
import './body.css';
import './buttons.css';
import './card.css';
import { GlobalStateProvider } from './componants/GlobalStateContext.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
     <GlobalStateProvider>
      <App />
      </GlobalStateProvider>
  </React.StrictMode>,
)



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
