import React from "react";
import NavBar from "./NavBar.jsx";
// import Button from "./Button.jsx";
// import Calendar from "./Calendar.jsx";
import RippleButton from "./RippleButton.jsx";
// import changeClipPath from "./js/card.js";
// import { useGlobalState } from './GlobalStateContext.jsx';

import Footer from './Footer';
// to improve on loading use "lazy loading"
const Pages = React.lazy(() => import("./Pages.jsx"));

function Heading() {
    // This is to control the showing and closing the page based on each page bool state. according to the state the needed class 
    // will be added to the page div
//   const { isOpen, toggleBool } = useGlobalState();

   
  
    return (  
        <>
            
            <header className="header" >
                
                <NavBar />
                
                <div className="header-container"> 
                <div className="header-body">
                <div className="bg-text">
                        <h1>Welcome to Riverside Development! </h1>
                            <div className="header-text"> <p>With the latest marketing techniques and cutting-edge development technologies, we bring your vision to life with care and
                                                            expertise. Focus on what truly matters while we handle your web development needs.</p></div>
                    </div>
                       
                        {/* < Button class="btn btn-teal loading " text="Learn More" />  */}
                        < RippleButton class="btn learn-more loading ripple-button header-btn" page={'about'} text="Learn More" link="#"/>
                        {/* < Button class="btn learn-more loading ripple-button header-btn" onClick={(e) => {  toggleBool('about')}} text="Get started" link="#contact"/> */}
    
                    </div>
                    
                     
                    
            
                </div>
                {/* <RippleOverlay /> */}
                
              
            </header>

            {/*Discription: The id contains all of the styling for the page to be shown  */}
        {/* ----- need to get the drop down to work on fixing time */}
            {/* <Calendar /> */}
            {/* ------- Contact Page -------- */}
            < Pages class="contact" />
            {/* -------- About page ------- */}
            < Pages class="about" />


            {/* < Contact title="" phone="333-333-3333" email="RiverSideDev@gmail.com"/> */}

            <Footer />
        </>
    )
}
export default Heading